import {BasePage} from "./BasePage";
import React, {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import {
  Alert,
  Button, Checkbox,
  FormControl, FormLabel, Heading, IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td, Textarea,
  Th,
  Thead,
  Tr, useToken
} from "@chakra-ui/react";
import {AddIcon, EditIcon} from "@chakra-ui/icons";
import {State, useHookstate} from "@hookstate/core";
import {InputForm} from "./InputForm";
import {Clinic, ClinicMapCoordinates, createDummyPosition, Institution, institutionNames} from "../types";
import {createInstitution, getInstitutionById, getInstitutions, updateInstitution} from "../net-utils";
import {CollectibleComponent} from "./CollectibleComponent";
import {OptionalInputCheckbox} from "./OptionalInputCheckbox";
import FileUploadButton from "./FileUploadButton";
import mapIceland from '../resources/map-iceland-cropped-no-dots.svg';
import {BiMap} from "react-icons/bi";
import {
  validateEmail,
  validateNotEmpty,
  validateNotZeroOrNegative,
  validatePhone,
  validatePostalNumber,
  validateSSN
} from "../validations";
import {DefaultSpinner} from "./DefaultSpinner";
import {cloneState} from "../heka-utils";
import {Link} from "react-router-dom";
import {ModalBase} from "./ModalBase";
import {PositionDisplay} from "./PositionPage";
import {globalUser} from "../App";

export function AdminPage() {
  const createInstitutionModalOpen = useHookstate(false)
  const institutions = useHookstate<Institution[] | null>(null)
  const user = useHookstate(globalUser).get()?.user
  useEffect(() => {
    (async () => {
      const response = await getInstitutions()
      institutions.set(response)
    })()
  }, []);

  useEffect(() => {
    if (user && !user.isAdmin) {
      window.location.href = '/'
      return;
    }
  }, [user]);
  const institutionsOrNull = institutions.ornull
  if (!institutionsOrNull) {
    return <DefaultSpinner/>
  }

  return <BasePage>
    <Link to={'/email-editor'}><Button>Senda fjölpóst</Button></Link>
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Stofnun</Th>
            <Th>Fjöldi eiganda</Th>
            <Th>Fjöldi heilsugæsla</Th>
          </Tr>
        </Thead>
        {institutionsOrNull.map(institution => <InstitutionRow key={institution.institutionId.get()}
          institution={institution}/>)}
      </Table>
    </TableContainer>
    <div css={css`display: flex;
      gap: 8px`}>
      <Button leftIcon={<AddIcon/>} onClick={() => createInstitutionModalOpen.set(true)}>
        Ný heilbrigðisstofnun
      </Button>
    </div>
    <CreateInstitutionModal open={createInstitutionModalOpen}/>
  </BasePage>;
}

function InstitutionRow(props: {institution: State<Institution>}) {
  const state = useHookstate(props.institution)
  const institution = props.institution.get()
  const modalOpen = useHookstate(false)
  return <Tbody>
    <Tr>
      <Td>
        <IconButton aria-label={"Breyta heilbrigðisstofnun"} icon={<EditIcon/>}
          onClick={() => modalOpen.set(true)}/>
      </Td>
      <Td>
        {institution.name}
      </Td>
      <Td>
        {institution.memberUsers.length}
      </Td>
      <Td>
        {institution.clinics.length}
      </Td>
    </Tr>
    <UpdateInstitutionModal open={modalOpen} institution={cloneState(state)}
      onSubmit={institution => state.set(institution)}/>
  </Tbody>
}

function CreateInstitutionModal(props: {
  open: State<boolean>,
}) {
  const institution = useHookstate(createBlankInstitution())
  async function submitInstitution() {
    await createInstitution(institution.get())
  }
  return <InstitutionModalInput institution={institution}
    open={props.open}
    submitFunction={submitInstitution}
    title={'Ný heilbrigðisstofnun'}/>
}

export function UpdateInstitutionModal(props: {
  open: State<boolean>,
  institution: Institution,
  onSubmit: (institution: Institution) => void,
  disallowChangeVisibility?: boolean
}) {
  const institution = useHookstate(props.institution)
  async function submitInstitution(newInstitution: Institution) {
    if (newInstitution.clinics.length < props.institution.clinics.length
      && !window.confirm(`ATH: Þú ert að fara eyða ${props.institution.clinics.length - newInstitution.clinics.length} heilsugæslu. Ertu viss um að þú viljir halda áfram?`)) {
      return
    }
    await updateInstitution(newInstitution)
    props.onSubmit(cloneState(institution))
  }

  return <InstitutionModalInput institution={institution}
    open={props.open}
    submitFunction={submitInstitution}
    title={'Breyta heilbrigðisstofnun'}
    disallowChangeVisibility={props.disallowChangeVisibility}
  />
}

function ClinicImagesUpload(props: {clinic: State<Clinic>}) {
  const clinic = useHookstate(props.clinic)
  const moreImagesState = useHookstate(clinic.images)
  const imagesOrNull = moreImagesState.ornull;

  useEffect(() => {
    if (!imagesOrNull) {
      moreImagesState.set([]);
    }
  }, []);

  if (!imagesOrNull) {
    return null;
  }

  return (
    <CollectibleComponent<string | undefined>
      display={(state, index) => (
        <FileUploadButton
          type="image"
          label={`Mynd ${index + 1} af heilsugæslu`}
          state={state}
        />
      )}
      elements={imagesOrNull as State<Array<string | undefined>>}
      construct={() => undefined}
      addMoreText="Bæta við mynd"
      disableAddMore={items => {
        if (items.length && items[items.length - 1] === undefined) {
          return true;
        }
        return items.length >= 10;
      }}
    />
  );
}

const PositionPreviewModal = (props: {
  open: State<boolean>,
  clinic: Clinic,
  institutionId: string,
}) => {
  const previewOpen = useHookstate(props.open);
  const clinic = props.clinic;
  const [institution, setInstitution] = useState(null as Institution | null);
  useEffect(() => {
    (async () => {
      setInstitution(await getInstitutionById(props.institutionId));
    })()
  }, [])
  if (!institution) {
    return null
  }
  return <ModalBase size={'5xl'} open={previewOpen}>
    <PositionDisplay position={createDummyPosition(clinic.name, institution.name)} clinic={clinic}
      refreshPosition={async () => {}}/>
  </ModalBase>
}

export function ClinicInputForm(props: {
  clinic: State<Clinic>,
  institutionId: string,
}) {
  const clinic = useHookstate(props.clinic)
  const coordinatesOpen = useHookstate(false);
  const previewOpen = useHookstate(false);
  return <FormControl css={css`display: flex; flex-direction: column; gap: 8px`}>
    <InputForm label={`Nafn`} placeholder={`Nafn á heilsugæslu`} state={clinic.name} validate={validateNotEmpty}/>
    <InputForm label={`Heimilisfang`} placeholder={`Heimilisfang heilsugæslu`} state={clinic.address} validate={validateNotEmpty}/>
    <div><FormLabel htmlFor={'about-clinic'}>Lýsing á heilsugæslu</FormLabel>
      <Textarea placeholder={'Um heilsugæslu'} value={clinic.description.ornull?.get() ?? ''}
        onChange={e => clinic.description.set(e.target.value)} id={'about-clinic'}/></div>
    <ClinicImagesUpload clinic={clinic}/>
    <Button leftIcon={<BiMap/>}
      onClick={() => {
        coordinatesOpen.set(true);
      }}>
      {clinic.mapCoordinates.get() !== undefined ? 'Breyta kortastaðsetningu' : 'Bæta við kortastaðsetningu'}
    </Button>
    <SelectCoordinatesModal open={coordinatesOpen} coordinates={clinic.mapCoordinates}/>
    <Button onClick={() => previewOpen.set(true)}>Sýnishorn</Button>
    {previewOpen.get() && <PositionPreviewModal open={previewOpen} institutionId={props.institutionId} clinic={clinic.get()}/>}
  </FormControl>
}

function InstitutionModalInput(props: {
  open: State<boolean>,
  submitFunction: (institution: Institution) => Promise<void>,
  title: string,
  institution: State<Institution>,
  disallowChangeVisibility?: boolean
}) {
  const state = useHookstate(props.institution);
  const error = useHookstate<string | null>(null)
  const onClose = () => {
    error.set(null)
    props.open.set(false);
  };
  async function submitInstitution() {
    error.set(null)
    const institution = state.get();
    if (institution.name === ""
      || institution.memberUsers.some(username => username === "")
      || institution.clinics.some(clinic => clinic.name === "" || clinic.address === "")
    ) {
      error.set("Vinsamlegast fylltu út alla reiti")
      return
    }
    await props.submitFunction(institution)
    onClose()
    window.location.reload();
  }
  const hasClinics = useHookstate(state.clinics.length > 0)

  function UserInputForm(props: {
    username: State<string>
  }) {
    // rerender annoyance
    const user = useHookstate(props.username)
    const state = useHookstate(user.get())
    return <InputForm label={`Notendanafn`} placeholder={`Notendanafn`} validate={validateNotEmpty} state={state}
      onBlur={s => user.set(s)}/>
  }

  useEffect(() => {
    if (!hasClinics.get()) {
      state.clinics.set([])
    }
  }, [hasClinics]);

  return <Modal isOpen={props.open.get()} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>{props.title}</ModalHeader>
      <ModalCloseButton/>
      <ModalBody css={css`display: flex;
        flex-direction: column;
        gap: 8px`}>
        <InputForm label={"Nafn"} placeholder={"Nafn heilbrigðisstofnunar"} state={state.name} validate={validateNotEmpty}/>
        <InputForm label={"Kennitala"} placeholder={"Kennitala heilbrigðisstofnunar"} state={state.ssn} validate={validateSSN}/>
        <InputForm label={"Skammstöfun"} placeholder={"Skammstöfun heilbrigðisstofnunar"} state={state.nameAbbr} validate={validateNotEmpty}/>
        <InputForm label={"Netfang"} placeholder={"Netfang heilbrigðisstofnunar"} state={state.email} validate={validateEmail}/>
        <InputForm label={"Símanúmer"} type={'tel'} placeholder={"Símanúmer heilbrigðisstofnunar"} state={state.phoneNumber} validate={validatePhone}/>
        <InputForm label={"Heimilisfang"} placeholder={"Heimilisfang heilbrigðisstofnunar"} state={state.address} validate={validateNotEmpty}/>
        <InputForm label={"Póstnúmer"} type={'number'} placeholder={"Póstnúmer heilbrigðisstofnunar"} state={state.postalCode} validate={validatePostalNumber}/>
        <InputForm label={"Bensíngreiðsla"} type="number" validate={validateNotZeroOrNegative} state={state.petrolPayment}
          subtext={"Upphæð í rafrænum reikningum sem heilbrigðisstofnun greiðir umsækjendum sem kjósa að ferðast með bíl til starfstöðvar"}
          prefix={'kr.'}/>
        {!props.disallowChangeVisibility && <Checkbox onChange={e =>
          state.hideOtherInstitutions.set(e.target.checked)
        } isChecked={state.hideOtherInstitutions.get() === true}>Fela stöður frá öðrum heilbrigðisstofnunum til
          stjórnanda þessarar heilbrigðisstofnunar</Checkbox>}
        <div>
          <Heading fontSize={'md'}>Stjórnendur</Heading>
          <CollectibleComponent<string> display={(value) =>
            <UserInputForm username={value}/>}
            elements={state.memberUsers} construct={() => ''} addMoreText={'Bæta við fleiri notendum'}
            lineSeparator={true}/>
        </div>
        {/*<OptionalInputCheckbox label={'Heilsugæslur'} open={hasClinics}>*/}
        {/*  <CollectibleComponent<Clinic> display={(value, index) =>*/}
        {/*    <ClinicInputForm clinic={value}/>*/}
        {/*  } elements={state.clinics}*/}
        {/*    construct={() => createBlankClinic()}*/}
        {/*    addMoreText={'Bæta við fleiri heilsugæslum'}*/}
        {/*    lineSeparator={true}/>*/}
        {/*</OptionalInputCheckbox>*/}
        {error.get() && <Alert status="error">
          {error.get()}
        </Alert>}
      </ModalBody>
      <ModalFooter css={css`display: flex;
        gap: 4px`}>
        <Button colorScheme={'green'} onClick={submitInstitution}>Vista</Button>
        <Button onClick={onClose}>Sleppa</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

function SelectCoordinatesModal(props: {
  open: State<boolean>,
  coordinates: State<ClinicMapCoordinates | undefined>
}) {
  const open = useHookstate(props.open)
  const onClose = () => open.set(false);
  const externalCoordinates = useHookstate(props.coordinates)
  const s = externalCoordinates.get()
  const coordinatesState = useHookstate<ClinicMapCoordinates | null>(s ? {...s} : null)
  const coordinates = coordinatesState.get()
  const [green] = useToken('colors', ['green.300']);

  return <Modal isOpen={open.get()} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>Velja staðsetningu</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <div css={css`position: relative`}>
          <img src={mapIceland} onClick={e => {
            const rect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - rect.left; //x position within the element.
            const y = e.clientY - rect.top;  //y position within the element.
            const calculatedX = x / rect.width * 772;
            const calculatedY = y / rect.height * 525;

            coordinatesState.set({x: calculatedX, y: calculatedY})
          }}/>
          <svg css={css`position: absolute; top: 0; width: 100%; pointer-events: none`} viewBox="0 0 772 525">
            {coordinates && <circle cx={coordinates.x} cy={coordinates.y} r={10} fill={green}/>}
          </svg>
        </div>
      </ModalBody>
      <ModalFooter css={css`display: flex; gap: 4px`}>
        <Button colorScheme={'green'}
          onClick={() => {
            if (coordinates) {
              externalCoordinates.set({...coordinates})
            }
            onClose()
          }}>Vista</Button>
        <Button onClick={onClose}>Sleppa</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

function createBlankInstitution(): Institution {
  return {
    name: '',
    ssn: '',
    nameAbbr: '',
    memberUsers: [],
    clinics: [],
    email: '',
    address: '',
    postalCode: '',
    dynamicShiftTypes: [],
    phoneNumber: ''
  }
}

export function createBlankClinic(): Clinic {
  return {
    name: '',
    address: '',
    mapCoordinates: undefined,
    primaryImagePath: ''
  }
}