import {BorderBox} from './BorderBox';
import {css} from '@emotion/react';
import {
  Avatar,
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip
} from '@chakra-ui/react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {BellIcon, CalendarIcon, StarIcon} from '@chakra-ui/icons';
import {FiLogOut} from 'react-icons/fi';
import {formatCurrency, formatDate, getUsername, removeUserJwt, setUserJwt} from "../heka-utils";
import {FaBriefcaseMedical, FaExclamation} from 'react-icons/fa';
import {AiOutlineUser} from 'react-icons/ai';
import {hookstate, useHookstate} from "@hookstate/core";
import {globalUser} from "../App";
import {RiBillLine} from "react-icons/ri";
import {
  countUnreadNotificationMessages,
  FavoritePosition,
  getFavoritePositionsForUser,
  getNotificationMessages,
  updateLastCheckedNotifications
} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {useTranslation} from "react-i18next";
import {
  CounterOfferReceived,
  NewPositionAlert, NewPositionRequest,
  NotificationMessage,
  PositionAccepted,
  PositionCancelled,
  PositionCancelledByInstitution,
  PositionUpdated
} from "../types";
import {IconType} from "react-icons";
import {GiPartyPopper} from "react-icons/gi";

export const globalFavoritePositions = hookstate(null as FavoritePosition[] | null)

function FavoriteMenuList() {
  const {t} = useTranslation();
  const favoritePositions = useHookstate(globalFavoritePositions).get()
  useEffect(() => {
    (async () => {
      globalFavoritePositions.set(
        await getFavoritePositionsForUser(getUsername())
      )
    })();
  }, []);
  if (!favoritePositions) {
    return <div css={css`width: 350px`}>
      <DefaultSpinner/>
    </div>
  }
  if (favoritePositions.length === 0) {
    return <Text textAlign={'center'}>Engar vistaðar stöður</Text>
  }
  return <>
    {favoritePositions.map(pos => {
      return <Link to={`/positions/${pos.positionId}`} key={pos._id}>
        <MenuItem icon={<StarIcon color={"yellow.400"}/>}>
          {t(pos.professionType)} á {pos.clinicName} tímabilið {formatDate(pos.beginDate)} - {formatDate(pos.endDate)}</MenuItem>
      </Link>
    })}
  </>;
}

interface NotificationItem {
  content: JSX.Element,
  icon: IconType,
  link: string,
  date: Date
}

export function processNotificationItems(notificationMessages: NotificationMessage[]) {
  return notificationMessages.map(msg => {
    if (msg.eventType === 'PositionAccepted') {
      const event = msg.event as PositionAccepted;
      return {
        content: <Text fontSize={'sm'}><b>{event.institutionName}</b> hefur staðfest þína umsókn fyrir stöðuna á &nbsp;
          starfsstöðinni <b>{event.clinicName} tímabilið {formatDate(event.beginDate)} - {formatDate(event.endDate)}</b>.&nbsp;
          Smelltu hér til þess að halda áfram.
        </Text>,
        icon: GiPartyPopper,
        link: `/contracts/${event.positionRequestId}`,
        date: msg.createdAt
      }
    }
    else if (msg.eventType === 'PositionCancelled') {
      const event = msg.event as PositionCancelled;
      return {
        content: <Text fontSize={'sm'}><b>{event.employeeName}</b> hefur hætt við stöðuna á starfsstöðinni&nbsp;
          <b>{event.clinicName} tímabilið {formatDate(event.beginDate)} - {formatDate(event.endDate)}</b>.&nbsp;
          Smelltu hér til þess að endurbirta auglýsingu eða skoða umsóknir.
        </Text>,
        icon: FaExclamation,
        link: `/positions/${event.positionId}`,
        date: msg.createdAt
      }
    }
    else if (msg.eventType === 'NewPositionAlert') {
      const event = msg.event as NewPositionAlert;
      return {
        content: <Text fontSize={'sm'}><b>{event.institutionName}</b> hefur stofnað nýja stöðu á óskatímabilinu þínu á&nbsp;
          starfsstöðinni <b>{event.clinicName} tímabilið {formatDate(event.beginDate)} - {formatDate(event.endDate)}</b>.&nbsp;
          Smelltu hér til þess að skoða.
        </Text>,
        icon: FaBriefcaseMedical,
        link: `/positions/${event.positionId}`,
        date: msg.createdAt
      }
    }
    else if (msg.eventType === 'PositionUpdated') {
      const event = msg.event as PositionUpdated;
      return {
        content: <Text fontSize={'sm'}>
          <b>{event.institutionName}</b> hefur uppfært stöðuna á starfsstöðinni&nbsp;
          <b>{event.clinicName} tímabilið {formatDate(event.beginDate)} - {formatDate(event.endDate)}</b> sem þú sóttir um.&nbsp;
          Smelltu hér til þess að skoða.
        </Text>,
        icon: FaBriefcaseMedical,
        link: `/positions/${event.positionId}`,
        date: msg.createdAt
      }
    }
    else if (msg.eventType === 'PositionCancelledByInstitution') {
      const event = msg.event as PositionCancelledByInstitution;
      return {
        content: <Text fontSize={'sm'}>
          <b>{event.institutionName}</b> hefur afbókað stöðuna &nbsp;
          <b>{event.clinicName} tímabilið {formatDate(event.beginDate)} - {formatDate(event.endDate)}</b> sem þú varst bókaður í.&nbsp;
          Smelltu hér til þess að skoða stöðuna.
        </Text>,
        icon: FaBriefcaseMedical,
        link: `/positions/${event.positionId}`,
        date: msg.createdAt
      }
    } else if (msg.eventType === 'CounterOfferReceived') {
      const event = msg.event as CounterOfferReceived;
      return {
        content: <Text fontSize={'sm'}>
          {event.isInstitutionOffer ? event.institutionName : event.employeeName} hefur sent móttilboð fyrir stöðuna <b>{event.clinicName} tímabilið {formatDate(event.beginDate)} - {formatDate(event.endDate)}</b>. Móttilboð: <b>{formatCurrency(event.totalAmount)}</b>
        </Text>,
        icon: FaBriefcaseMedical,
        link: `/positions/${event.positionId}/counter-offer/${event.positionRequestOfferId}`,
        date: msg.createdAt
      }
    } else if (msg.eventType === 'NewPositionRequest') {
      const event = msg.event as NewPositionRequest;
      return {
        content: <Text fontSize={'sm'}>
          {event.isInstitutionOffer ? event.institutionName : event.employeeName} hefur sent umsókn fyrir
          stöðuna <b>{event.clinicName} tímabilið {formatDate(event.beginDate)} - {formatDate(event.endDate)}</b>. {event.hasCounterOffer ? `Móttilboð er ${formatCurrency(event.totalAmount)}` : ''}
        </Text>,
        icon: FaBriefcaseMedical,
        link: `/positions/${event.positionId}/manage`,
        date: msg.createdAt
      }
    }
    throw new Error(`Unknown event type ${msg.eventType}`)
  });
}

function NotificationsMenuList() {
  const [notificationMessages, setNotificationMessages] = useState(null as NotificationMessage[] | null);
  const navigate = useNavigate();
  const userState = useHookstate(globalUser)
  const user = userState.get()?.user
  useEffect(() => {
    (async () => {
      const username = getUsername();
      setNotificationMessages(
        await getNotificationMessages(username)
      )
      await updateLastCheckedNotifications(username)
    })();
  }, []);
  if (!notificationMessages || !user) {
    return <MenuItem>
      <div css={css`width: 350px`}>
        <DefaultSpinner/>
      </div>
    </MenuItem>
  }
  if (notificationMessages.length === 0) {
    return <Text textAlign={'center'}>Engar tilkynningar</Text>
  }
  const messages: NotificationItem[] = processNotificationItems(notificationMessages);
  return <>
    {messages.map((msg, i) => {
      const backgroundColor = msg.date <= user.lastCheckedNotifications ? 'gray.50' : 'white'
      return <MenuItem css={css`display: flex;
          gap: 8px;
          align-items: center;
          max-width: 400px;`}
        backgroundColor={backgroundColor}
        onClick={() => navigate(msg.link)} key={i}>
        <div css={css`display: flex;
            flex-direction: column;
            gap: 4px`}>
          <Icon boxSize={6} as={msg.icon} css={css`margin: 0 auto`} color={"gray.400"}/>
          <Badge>{formatDate(msg.date)}</Badge>
        </div>
        {msg.content}
      </MenuItem>;
      }
    )}
    <MenuItem onClick={() => navigate('/my-notifications')}>
      <Text css={css`display: block; width: 100%; text-align: center`}>
        Skoða allar tilkynningar
      </Text>
    </MenuItem>
  </>;
}

function NotificationIconButton() {
  const {pathname} = useLocation();
  const [numUnread, setNumUnread] = useState(0);
  useEffect(() => {
    (async () => {
        const unread = await countUnreadNotificationMessages(getUsername());
        setNumUnread(unread.unread);
    })();
  }, [pathname]);
  return <Tooltip label={'Tilkynningar'}><MenuButton css={css`position: relative`}>
    <BellIcon boxSize={8} color={"gray.600"}/>
    {numUnread !== 0 && <Box as={"span"} color={"white"} position={"absolute"} top={"16px"} right={"0px"} fontSize={"0.6rem"}
      fontWeight={"bold"}
      bgColor={"red"} borderRadius={"50%"} width={15} height={15} zIndex={9999} p={"1px"} lineHeight={"12px"}
      css={css`text-indent: -1px`}>
      {numUnread}
    </Box>}
  </MenuButton></Tooltip>;
}

export function NavigationBar() {
  const excludePaths = new Set(['/', '/signup', '/signup/resume', '/signup/bank', '/accept-invitation', '/verification-pending'])
  const location = useLocation()
  const pathname = location.pathname;
  const navigate = useNavigate();
  const user = useHookstate(globalUser).get();
  if (excludePaths.has(pathname)) {
    return <></>
  }
  if (!user) {
    return <></>
  }
  return <BorderBox css={css`margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center`}>
    <Breadcrumb separator={'-'}>
      <BreadcrumbItem>
        <BreadcrumbLink to={'/home'} as={Link}>
          Opnar stöður
        </BreadcrumbLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to={`/users/${getUsername()}`}>
          Mín síða
        </BreadcrumbLink>
      </BreadcrumbItem>
      {user?.institutions?.length && (() => {
        return user.institutions.map(institution => <BreadcrumbItem key={institution.id}>
          <BreadcrumbLink to={`/institutions/${institution.id}`} as={Link}>
            {institution.name}
          </BreadcrumbLink>
        </BreadcrumbItem>)
      })()}
      {user?.user.isAdmin && <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={'/admin'}>
              Stjórnun
          </BreadcrumbLink>
      </BreadcrumbItem>}
    </Breadcrumb>
    <div css={css`display: flex;
      gap: 8px;
      align-items: center`}>
      <Menu isLazy>
        <Tooltip label={'Vistaðar stöður'}>
          <MenuButton>
              <StarIcon boxSize={6} color={'gray.600'}/>
          </MenuButton>
        </Tooltip>
        <MenuList>
          <FavoriteMenuList/>
        </MenuList>
      </Menu>
      <Menu isLazy>
        <NotificationIconButton/>
        <MenuList>
          <NotificationsMenuList/>
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton>
          <Avatar size={'sm'} name={user?.user.electronicId.name} src={user?.user.preferences.links.picture ?? ''}/>
        </MenuButton>
        <MenuList>
          <MenuItem icon={<AiOutlineUser/>} onClick={() => {
            navigate(`/users/${getUsername()}`)
          }}>
            Mín síða
          </MenuItem>
          <MenuItem icon={<CalendarIcon/>} onClick={() => {
            navigate(`/my-calendar`)
          }}>
            Mitt dagatal
          </MenuItem>
          <MenuItem icon={<RiBillLine/>} onClick={() => {
            navigate(`/my-contracts`)
          }}>
            Mínir samningar
          </MenuItem>
          <MenuItem icon={<RiBillLine/>} onClick={() => {
            navigate(`/my-invoices`)
          }}>
            Mínir reikningar
          </MenuItem>
          <MenuItem icon={<RiBillLine/>} onClick={() => {
            navigate(`/my-applications`)
          }}>
            Mínar umsóknir
          </MenuItem>
          {/*
            process.env.NODE_ENV === 'development' && <MenuItem icon={<FiLogOut/>} onClick={
              () => {
                const user = localStorage.getItem('heka-user-jwt');
                const doctorUser = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJoZWthIiwidW52ZXJpZmllZCI6ZmFsc2UsImlhdCI6MTcyNzEwNjMwMSwidXNlcm5hbWUiOiJodWdydW4ubGlsamEucmFnbmFyc2RvdHRpciJ9.P4Pf5BLTPyk4yWSQ5LBXJH3yXGn9TWrnaucmyjg8AT4';
                const adminUser = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJoZWthIiwidW52ZXJpZmllZCI6ZmFsc2UsImlhdCI6MTcyNzEwNjM5OCwidXNlcm5hbWUiOiJrb3JtYWt1ci5icmVraS5ndW5ubGF1Z3Nzb24ifQ.6w5V1LFkFtS8zTlC-SfAOmtE4Y9GzG3v9Rdf3l59bto'
                setUserJwt(user === doctorUser ? adminUser : doctorUser)
                window.location.reload();
              }
            }>Skipta um notenda</MenuItem>
          */}
          <MenuItem icon={<FiLogOut/>} onClick={
            () => {
              removeUserJwt()
              navigate("/")
            }
          }>Skrá út</MenuItem>
        </MenuList>
      </Menu>
    </div>
  </BorderBox>;
}