import React, {useEffect, useState} from 'react';
import {BasePage} from "./BasePage";
import {BorderBox} from "./BorderBox";
import {Heading, Table, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr} from "@chakra-ui/react";
import {InputForm} from "./InputForm";
import {css} from "@emotion/react";
import {GoButton} from "./GoButton";
import {useHookstate} from "@hookstate/core";
import {useTranslation} from "react-i18next";
import {Position, PositionRequest, PositionRequestOffer, ShiftTypeName} from "../types";
import {Link, useParams} from "react-router-dom";
import {createCounterOffer, getPositionById, getPositionRequestByPositionId} from "../net-utils";
import {cloneDeep, isEqual} from "lodash";
import {DefaultSpinner} from "./DefaultSpinner";
import {formatCurrency} from "../heka-utils";
import {globalUser} from "../App";
import AlertDisplay from "./AlertDisplay";

function getCounterOffer(positionRequest: PositionRequest, offerId: string) {
  for (const counterOffer of positionRequest.counterOffers.slice().reverse()) {
    if (counterOffer._id === offerId) {
      return counterOffer;
    }
  }
  if (offerId === positionRequest.request.offer?._id) {
    return positionRequest.request.offer;
  }
  return null;
}

function getNewestOffer(positionRequest: PositionRequest) {
  const length = positionRequest.counterOffers.length;
  if (length === 0) return positionRequest.request.offer;
  return positionRequest?.counterOffers[length - 1];
}

const CounterOfferPage = () => {
  const params = useParams();
  const submitError = useHookstate(null as null | string);
  const [positionRequest, setPositionRequest] = useState(null as PositionRequest | null);
  const [position, setPosition] = useState(null as Position | null);
  const {t} = useTranslation();
  const positionId = params.positionId as string | undefined
  const offerId = params.offerId as string | undefined
  const newOfferState = useHookstate(null as PositionRequestOffer | null);
  const newOfferStateOrNull = newOfferState.ornull;
  const user = useHookstate(globalUser).get()?.user;
  const thisOffer = positionRequest && offerId ? getCounterOffer(positionRequest, offerId) : null;
  const newestOffer = positionRequest && getNewestOffer(positionRequest);

  useEffect(() => {
    if (!positionId) {
      throw new Error('Position id is undefined');
    }
    if (!offerId) {
      throw new Error('Offer id is undefined');
    }
    (async () => {
      if (!newOfferStateOrNull) {
        const positionRequest = await getPositionRequestByPositionId(positionId);
        setPositionRequest(positionRequest);
        const offer = getCounterOffer(positionRequest, offerId);

        if (!offer) {
          throw new Error('Offer is undefined');
        }
        const offerClone = cloneDeep(offer);
        offerClone.message = '';
        newOfferState.set(offerClone);
      }
    })();
    (async () => {
      setPosition(await getPositionById(positionId));
    })();
  }, [positionId]);

  const onCreateCounterOffer = async () => {
    if (!newOfferStateOrNull || !positionRequest) return;
    await createCounterOffer(positionRequest._id, newOfferState.get()!);
    setPositionRequest(await getPositionRequestByPositionId(positionRequest.request.positionId));
    setOfferUpdated(true);
  };
  const [offerUpdated, setOfferUpdated] = useState(false);

  if (!position || !positionRequest || !newOfferStateOrNull) {
    return <DefaultSpinner/>
  }

  const calculateTotal = (shiftPays: Partial<Record<ShiftTypeName, number>>) => {
    return Object.entries(shiftPays).reduce((total, [shiftType, pay]) => {
      const shiftCount = position!.shifts.filter(s => s.types.includes(shiftType)).length;
      return total + (pay || 0) * shiftCount;
    }, 0);
  }

  const originalTotal = calculateTotal(positionRequest.request.offer!.shiftTypePays);
  const newTotal = calculateTotal(newOfferState.get()!.shiftTypePays);
  const areOffersEqual = isEqual(thisOffer?.shiftTypePays, newOfferState.get()!.shiftTypePays);
  const isInstitutionOffer = positionRequest.counterOffers[positionRequest.counterOffers.length - 1]?.createdBy !== user?.username;
  const isNewestOfferMine = newestOffer?.createdBy === user?.username;

  return (
    <BasePage>
      <BorderBox css={css`display: flex; flex-direction: column; gap: 8px; padding: 24px;`}>
        <Heading fontSize={'2xl'}>{t('Counter offer')}</Heading>
        {isNewestOfferMine && <AlertDisplay status={'success'}>{
          offerUpdated ? t('Counter offer updated') : t('Counter offer sent')
        }</AlertDisplay>}
        <Text color={'gray.600'}>{t('Adjust the base prices for each shift type and submit your counter offer.')}</Text>
        { !thisOffer?.isInstitutionOffer &&
          <Text color={'gray.600'}>{t('Offer from')} <Link
          to={`/users/${thisOffer?.createdBy}`}>{thisOffer?.createdBy}</Link></Text>}

        {thisOffer?.message && <div>
            <Heading fontSize={'lg'}>{t('Message')}</Heading>
            <Text>{thisOffer.message}</Text>
        </div>}

        <Link target={'_blank'} to={`/positions/${position._id!!}`}>Skoða stöðu</Link>
        <div css={css`margin-bottom: 32px;`}/>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t('Shift')}</Th>
                <Th isNumeric>{t('Base price')}</Th>
                <Th isNumeric>{t('Total')}</Th>
                <Th isNumeric>{t('Counter offer price')}</Th>
                <Th isNumeric>{t('Total (counter offer)')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(positionRequest.request.offer!.shiftTypePays).map(([shiftType, originalPay]) => {
                const shiftCount = position!.shifts.filter(s => s.types.includes(shiftType)).length;
                const newPay = newOfferState.get()!.shiftTypePays[shiftType as ShiftTypeName] || 0;
                return (
                  <Tr key={shiftType}>
                    <Td>{t(shiftType)} × {shiftCount}</Td>
                    <Td isNumeric>{formatCurrency(originalPay || 0)}</Td>
                    <Td isNumeric>{formatCurrency((originalPay || 0) * shiftCount)}</Td>
                    <Td isNumeric>
                      <InputForm
                        label={''}
                        prefix={'kr.'}
                        type={'number'}
                        state={newOfferStateOrNull.shiftTypePays[shiftType as ShiftTypeName]}
                      />
                    </Td>
                    <Td isNumeric>
                      {formatCurrency(newPay * shiftCount)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th><b>{t('Total')}</b></Th>
                <Th isNumeric></Th>
                <Th isNumeric>{formatCurrency(originalTotal)}</Th>
                <Th></Th>
                <Th isNumeric>
                  {formatCurrency(newTotal)}
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
        <Textarea placeholder={t('Message') ?? ''} value={newOfferStateOrNull?.message.get()} onChange={e => newOfferStateOrNull?.message.set(e.target.value)}>

        </Textarea>
        <div css={css`margin-top: 32px;`}/>
        <div css={css`display: flex; justify-content: flex-end; width: 100%`}>
          <div css={css`display: flex; gap: 8px`}>
            <GoButton colorScheme={!areOffersEqual ? 'pink' : 'gray'} isDisabled={areOffersEqual} submitError={submitError}  onClick={onCreateCounterOffer}>{
              !isNewestOfferMine ? t('Send a counter offer') : t('Update counter offer')
            }</GoButton>
            <GoButton colorScheme={areOffersEqual ? 'pink' : 'gray'} isDisabled={!areOffersEqual} submitError={submitError} onClick={onCreateCounterOffer}>{t('Accept offer')}</GoButton>
          </div>
        </div>
      </BorderBox>
    </BasePage>
  );
};

export default CounterOfferPage;