import React, {HTMLInputTypeAttribute} from "react";
import {
  FormControl, FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon
} from "@chakra-ui/react";
import {css, SerializedStyles} from "@emotion/react";
import { DatePicker } from './DatePicker';
import {State, useHookstate} from "@hookstate/core";
import {ReactDatePickerProps} from "react-datepicker";

export function InputForm(p: {
  label?: string, placeholder?: string,
  subtext?: string,
  type?: HTMLInputTypeAttribute,
  prefix?: string,
  css?: SerializedStyles,
  className?: string,
  state?: State<string> | State<number> | State<number | undefined> | State<string | undefined>,
  isRequired?: boolean,
  validate?: (value: string) => string | null,
  onBlur?: (value: string) => void,
  isDisabled?: boolean,
  autoFocus?: boolean,
}) {
  const error = useHookstate<string | null>(null);
  const defaultState = useHookstate<string | number>('')
  const state = useHookstate(p.state || defaultState)
  return <FormControl className={p.className} isRequired={p.isRequired} isInvalid={error.get() !== null}>
    {p.label && <FormLabel htmlFor={p.label}>{p.label}</FormLabel>}
    {p.type === 'date' ? <DatePicker onChange={() => {}} placeholderText={p.placeholder || p.label || ''}/> : <InputGroup>
      <Input autoFocus={p.autoFocus} id={p.label} type={p.type} placeholder={p.placeholder || p.label || ''} value={String(state.get() ?? '')}
             onChange={e => {
               const val = typeof state.get() === 'number' && state.get() !== "NaN" ? parseInt(e.target.value) : e.target.value
               state.set(val)
             }}
             onBlur={() => {
               const stateValue = String(state.get())
               error.set(p.validate?.(stateValue) || null);
               p.onBlur?.(stateValue)
             }} isDisabled={p.isDisabled}/>
      {p.prefix && <InputRightAddon children={p.prefix}/>}
    </InputGroup>}
    {p.subtext && !error.get() && <FormHelperText>{p.subtext}</FormHelperText>}
    {error.get() && <FormErrorMessage>{error.get()}</FormErrorMessage>}
  </FormControl>;
}

function GenericInputForm(props: {
  label: string,
  input: React.ReactNode,
  error: State<string | null>,
  subtext?: string,
} & FormControlProps) {
  const {label, input, error, ...rest} = props
  return <FormControl isInvalid={!!error.get()} {...rest}>
    <FormLabel htmlFor={label}>{label}</FormLabel>
    {input}
    {props.subtext && !error.get() && <FormHelperText>{props.subtext}</FormHelperText>}
    {error.get() && <FormErrorMessage>{error.get()}</FormErrorMessage>}
  </FormControl>
}

function TextInputForm(props: {
  state: State<string>,
  label: string,
  subtext?: string,
  validate?: (value: string) => string | null,
  placeholder?: string,
  prefix?: string,
}) {
  const state = useHookstate(props.state)
  const error = useHookstate<string | null>(null);
  const input = <InputGroup>
    <Input id={props.label} placeholder={props.placeholder ?? props.label} value={state.get() ?? ''}
      onChange={e => state.set(e.target.value)}
      onBlur={() => {
        const value = state.get()
        error.set(props.validate?.(value) ?? null);
      }}
    />
    {props.prefix && <InputRightAddon children={props.prefix}/>}
  </InputGroup>
  return <GenericInputForm label={props.label} subtext={props.subtext} error={error} input={input}/>
}

export function DateInputForm(props: {
  state: State<Date> | State<Date | undefined>,
  label: string,
  subtext?: string,
  isDisabled?: boolean,
} & Omit<ReactDatePickerProps, 'onChange'>) {
  const state = useHookstate(props.state)
  const {label, subtext, isDisabled, ...rest} = props
  const error = useHookstate<string | null>(null);
  const disabledCss = css`opacity: 0.5; cursor: not-allowed;`
  const input = <DatePicker disabled={isDisabled} css={isDisabled ? disabledCss : undefined}
    selected={state.get()} onChange={date => date && state.set(date)} placeholderText={label} isClearable={false}
    {...rest}/>
  return <GenericInputForm label={label} subtext={subtext} error={error} input={input}/>
}