import React, {useEffect} from 'react';
import { css, Global } from '@emotion/react'
import './App.css';
import {Center, ChakraProvider, Heading, useToken} from '@chakra-ui/react';
import {BrowserRouter, Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {WelcomePage} from './components/WelcomePage';
import {SignupElectronicIDPage} from './components/SignupElectronicIDPage';
import {SignupResumePage, UpdateResumePage} from './components/SignupResumePage';
import {BankSignupPage, BankUpdatePage} from './components/BankSignupPage';
import {UserPage} from './components/UserPage';
import {HomePage} from './components/HomePage';
import {InstitutionPage} from './components/InstitutionPage';
import {CreatePositionPage, UpdatePositionPage} from './components/CreatePositionPage';
import {PositionPage} from './components/PositionPage';
import {InvoicePage} from './components/InvoicePage';
import {UserCalendarPage} from './components/UserCalendarPage';
import {ClinicPage} from './components/ClinicPage';
import {PositionManagePage} from "./components/PositionManagePage";
import {BsCalendarFill} from "react-icons/bs";
import {FaNotesMedical} from "react-icons/fa";
import {ContractPageEx} from './components/ContractPageEx';
import {AdminPage} from "./components/AdminPage";
import {hookstate} from "@hookstate/core";
import {UserResponse} from "./types";
import {getUserJwt, getUsername, parseUserJwt, removeUserJwt, setUserJwt} from "./heka-utils";
import {getUser, NetworkError, refreshUserJwt} from "./net-utils";
import ContractCreatedPage from "./components/ContractCreatedPage";
import UserContractsPage from "./components/UserContractsPage";
import NotificationsPage from "./components/NotificationsPage";
import UserPositionRequestsPage from "./components/UserPositionRequestsPage";
import ClinicPositionHistoryPage from "./components/ClinicPositionHistoryPage";
import InviteAdminPage from "./components/InviteAdminPage";
import UserInvoicesPage from "./UserInvoicesPage";
import {VerificationPendingPage} from "./components/VerificationPendingPage";
import TermsPage from "./components/TermsPage";
import NotFoundPage from "./components/NotFoundPage";
import {EmailEditor} from "react-email-editor";
import EmailEditorPage from "./components/EmailEditorPage";
import ContractOutsiderPage from "./components/ContractOutsiderPage";
import CounterOfferPage from "./components/CounterOfferPage";

export const globalUser = hookstate<UserResponse | null>(null)

function SiteHeader() {
  const [pink, lessPink, mostPink] = useToken('colors', ['pink.600', 'gray.600', 'red.900']);
  return <div css={css`margin-top: 20px; margin-bottom: 20px;`}>
    <Link to={'/'}>
      <div css={css`display: flex; flex-direction: column`}>
        <Heading css={css`text-align: center; display: flex; align-items: center; margin: 0 auto`}>
          <div css={css`position: relative`}>
            <BsCalendarFill css={css`color: ${pink}`}/>
            <FaNotesMedical css={css`position: absolute; top: 2px; margin-left: 6px; color: white`}/>
          </div>
          <span css={css`margin-left: 4px; `}>Heka</span>
        </Heading>
        <Heading size={'md'} css={css`text-align: center; color: ${lessPink}`}>
          Afleysingar heilbrigðisstarfsfólks
        </Heading>
      </div>
    </Link>
    </div>;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export async function refreshUser() {
  const userResponse = await getUser(getUsername());
  globalUser.set(userResponse)
  return userResponse.user
}

function AppRoutes() {
  const navigate = useNavigate()
  useEffect(() => {
    const userJwt = getUserJwt();
    if (userJwt !== null && globalUser.get() === null) {
      (async () => {
        try {
          const user = await refreshUser();
          if (!user.unverified && parseUserJwt(userJwt).unverified) {
            const newJwt = await refreshUserJwt();
            setUserJwt(newJwt)
          }
        } catch (e) {
          if (e instanceof NetworkError && (e.response.status === 401 || e.response.status === 404)) {
            removeUserJwt()
            navigate("/")
          }
        }
      })()
    }
  }, []);
  return <Routes>
    <Route path={"/"} element={<WelcomePage/>}/>
    <Route path={"/signup"} element={<SignupElectronicIDPage/>}/>
    <Route path={"/signup/resume"} element={<SignupResumePage/>}/>
    <Route path={"/signup/bank"} element={<BankSignupPage/>}/>
    <Route path={"/users/:username"} element={<UserPage/>}/>
    <Route path={"/my-calendar"} element={<UserCalendarPage/>}/>
    <Route path={"/users/:username/edit"} element={<UpdateResumePage/>}/>
    <Route path={"/users/:username/bank/edit"} element={<BankUpdatePage/>}/>
    <Route path={"/home"} element={<HomePage/>}/>
    <Route path={"/institutions/:institutionId"} element={<InstitutionPage/>}/>
    <Route path={"/institutions/:institutionId/positions/create"} element={<CreatePositionPage/>}/>
    <Route path={"/institutions/:institutionId/positions/:positionId/update"} element={<UpdatePositionPage/>}/>
    <Route path={"/positions/:positionId"} element={<PositionPage/>}/>
    <Route path={"/positions/:positionId/invoice"} element={<InvoicePage/>}/>
    <Route path={"/positions/:positionId/manage"} element={<PositionManagePage/>}/>
    <Route path={"/institutions/:institutionId/clinics/:clinicId"} element={<ClinicPage/>}/>
    <Route path={"/contracts/:requestId"} element={<ContractPageEx/>}/>
    <Route path={"/contracts/:contractId/overview"} element={<ContractCreatedPage/>}/>
    <Route path={"/admin"} element={<AdminPage/>}/>
    <Route path={"/my-contracts"} element={<UserContractsPage/>}/>
    <Route path={"/my-invoices"} element={<UserInvoicesPage/>}/>
    <Route path={"/my-notifications"} element={<NotificationsPage/>}/>
    <Route path={"/my-applications"} element={<UserPositionRequestsPage/>}/>
    <Route path={"/institutions/:institutionId/clinics/:clinicId/position-history"} element={<ClinicPositionHistoryPage/>}/>
    <Route path={"/accept-invitation"} element={<InviteAdminPage/>}/>
    <Route path={"/verification-pending"} element={<VerificationPendingPage/>}/>
    <Route path={"/terms"} element={<TermsPage/>}/>
    <Route path={"/not-found"} element={<NotFoundPage/>}/>
    <Route path={"/email-editor"} element={<EmailEditorPage/>}/>
    <Route path={"/institutions/:institutionId/outsider-contract"} element={<ContractOutsiderPage/>}/>
    <Route path={"/positions/:positionId/counter-offer/:offerId"} element={<CounterOfferPage/>}/>
  </Routes>;
}

function App() {
  return (
    <ChakraProvider>
      <Global styles={css`
        .fc-toolbar-chunk {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
        }
        .fc-button-group {
          margin-left: 0 !important;
        }
      `}/>
      <BrowserRouter>
        <ScrollToTop/>
        <Center flexDirection={'column'}>
          <SiteHeader/>
          <AppRoutes/>
        </Center>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
